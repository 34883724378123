import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class LogDataDialog extends React.Component {
  render() {
    const json = JSON.parse(this.props.data);
    const prettyJson = JSON.stringify(json, null, 2);

    console.log('this.props', this.props);

    return (
      <Dialog onClose={this.props.onClose} open={this.props.open}>
        <DialogTitle onClose={this.props.onClose}>Logi andmed</DialogTitle>
        <DialogContent dividers>
          <Typography component="pre" style={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
            {prettyJson}
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withRoot(withStyles(styles)(LogDataDialog));
import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';

import LogDataDialog from './LogDataDialog';
import { Divider } from '@material-ui/core';


class LogTable extends React.Component {

  actionLabelValueMap = {
    CREATE_CLIENT: "Isik loodud",
    UPDATE_CLIENT: "Isik uuendatud",
    DELETE_CLIENT: "Isik eemaldatud",
    DOWNLOAD_CLIENT: "Isik andmed alla laaditud",
    READ_CLIENT: "Isiku(te) andmeid loetud",
    CREATE_INCIDENT: "Juhtum loodud",
    UPDATE_INCIDENT: "Juhtum uuendatud",
    DELETE_INCIDENT: "Juhtum eemaldatud",
    READ_INCIDENT: "Juhtumi andmeid loetud",
    CREATE_SESSION: "Sessioon loodud",
    UPDATE_SESSION: "Sessioon uuendatud",
    DELETE_SESSION: "Sessioon eemaldatud",
    READ_SESSION: "Sessiooni andmeid loetud",
  };

  getActionLabel = (action) => {
    if (!this.actionLabelValueMap[action]) {
      return action;
    }
    return this.actionLabelValueMap[action];
  };

  state = {
    isLogDataDialogOpen: false,
    currentLogData: null,
  };

  handleLogDataDialogOpen = (data) => {
    this.setState({
      isLogDataDialogOpen: true,
      currentLogData: data,
    });
  };

  handleLogDataDialogClose = () => {
    this.setState({
      isLogDataDialogOpen: false,
      currentLogData: null
    });
  };

  createSortHandler = (property) => {
    this.props.onRequestSort(property);
  };

  formatToLocalTime = (utcDateString) => {
    // Parse the UTC date string as UTC by appending 'Z' to indicate zero UTC offset
    const date = new Date(utcDateString + 'Z');

    // Format the date manually to "YYYY-MM-DD HH:mm:ss"
    const year = date.getFullYear();
    const month = date.getMonth() + 1;  // getMonth returns 0-11
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Ensuring two-digit representations for month, day, hours, minutes, and seconds
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ` +
                          `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    return formattedDate;
  };

  render() {
    const {
      logs,
      page,
      rowsPerPage,
      handleChangePage,
      handleChangeRowsPerPage,
      totalCount,
      order,
      orderBy
    } = this.props;

    return (
      <>
        <Table style={{ tableLayout: 'auto' }}>
          {this.props.tableTitle && (
            <TableHead>
              <TableCell colSpan={4}>
                <div style={{fontWeight: "700"}}>
                  {this.props.tableTitle}
                </div>
              </TableCell>
            </TableHead>
          )}
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? order : 'asc'}
                  onClick={() => this.createSortHandler('created_at')}
                  hideSortIcon={false}
                >
                  Aeg
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'action'}
                  direction={orderBy === 'action' ? order : 'asc'}
                  onClick={() => this.createSortHandler('action')}
                  hideSortIcon={false}
                >
                  Tegevus
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'user_name'}
                  direction={orderBy === 'user_name' ? order : 'asc'}
                  onClick={() => this.createSortHandler('user_name')}
                >
                  Tegija
                </TableSortLabel>
              </TableCell>
              <TableCell>
                Andmed
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map(n => {
              return (
                <TableRow key={n.id}>
                  <TableCell>
                    {this.formatToLocalTime(n.created_at)}
                  </TableCell>
                  <TableCell>
                  {this.getActionLabel(n.action)}
                  </TableCell>
                  <TableCell>
                    {n.user_name}
                  </TableCell>
                  <TableCell>
                  <Button variant="outlined" color="primary" onClick={() => this.handleLogDataDialogOpen(n.data)}>
                    Vaata andmeid
                  </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 20, 50, 100, 1000]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <LogDataDialog
          data={this.state.currentLogData}
          open={this.state.isLogDataDialogOpen}
          onClose={this.handleLogDataDialogClose}
        />
      </>
    );
  }
  
};

LogTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default LogTable;